import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './ItemCard.scss';

const ItemCard = ({ className, children }) => {
    return (
        <div className={classNames('item-card', className)}>
            {children}
        </div>
    )
}

ItemCard.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
}

export default ItemCard
