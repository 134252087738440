import React from 'react';
import { graphql, Link } from 'gatsby';
import { GatsbyImage } from "gatsby-plugin-image";
import classNames from 'classnames';
import './portfolio.scss';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { projectExperiences, workExperiences, academicExperiences } from '../assets/texts/experiences-content';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import ItemCard from '../components/ItemCard';
import { pathForPortfolioSlug } from '../helper/navigation-helper';

function PortfolioPage({ data, location }) {
    const portfolioItems = data.allMarkdownRemark.edges.map((edge) => edge.node)
    // If `true`, the layout will set the Timeline- and Portfolio-sections side-by-side (on large-screens).
    const timelineAndPortfolioSideBySide = portfolioItems.length < 4;

    const renderSkillTags = (skills) => (
        <div className="chip-container">
            {skills.map((skill, index) => (
                <div key={index} className="chip-root">
                    <div className="chip-left-accessory-view" />
                    <div className="chip-label">
                        {skill}
                    </div>
                </div>
            ))}
        </div>
    )

    const renderTimelineItems = (experienceItems) => (
        experienceItems.map((item, index) => (
            <div key={index} className="item">
                <div className="date color-primary">
                    <span>{item.dateString}</span>
                    {
                        item.isActive ?
                            <FontAwesomeIcon icon={faStar} alt="" role="presentation" /> : <React.Fragment />
                    }
                </div>
                <div className="subtitle color-primary">{item.subtitleString}</div>
                <div className="title">{item.titleString}</div>
                {
                    Boolean(item.descriptionString) ?
                        <div className="description">{item.descriptionString}</div> : <React.Fragment />
                }
                {
                    Boolean(item.skills) ?
                        <div className="skills">{renderSkillTags(item.skills)}</div> : <React.Fragment />
                }
            </div>
        ))
    );

    const iconStyle = { borderRadius: "16px" }

    return (
        <Layout location={location} mainElementClassname="portfolio-page">
            <SEO title="Portfolio" />
            <div className={classNames(
                'container',
                { 'portfolio-page--container': timelineAndPortfolioSideBySide }
            )}>
                <section>
                    <div className="section-intro">
                        <h3>Portfolio</h3>
                        <div className="line homepage-line" />
                    </div>
                    <div className='portfolio-cards'>
                        {portfolioItems.map((portfolioItem) => (
                            <Link key={portfolioItem.id} to={pathForPortfolioSlug(portfolioItem.fields.slug)}>
                                <ItemCard className='item-card'>
                                    {portfolioItem.frontmatter.teaserImage ? <React.Fragment>
                                        <div className='bg' aria-hidden style={{
                                            backgroundImage: `url(${portfolioItem.frontmatter.teaserImage.publicURL})`,
                                            ...(portfolioItem.frontmatter.teaserBackgroundSize ? {
                                                backgroundSize: portfolioItem.frontmatter.teaserBackgroundSize,
                                            } : {}),
                                            ...(portfolioItem.frontmatter.teaserBackgroundPosition ? {
                                                backgroundPosition: portfolioItem.frontmatter.teaserBackgroundPosition,
                                            } : {}),
                                        }} />
                                        <div className='bg-overlay' aria-hidden />
                                    </React.Fragment> : null}
                                    <div className='item-card--content'>
                                        <div className='accessory-leading flex'>
                                            {portfolioItem.frontmatter.iconImage ? <GatsbyImage
                                                style={iconStyle}
                                                // Applying border-radius directly to `img`-element instead of `GatsbyImage`-container-element
                                                // as a workaround for Safari 15.
                                                imgStyle={iconStyle}
                                                image={portfolioItem.frontmatter.iconImage.childImageSharp.gatsbyImageData}
                                            /> : null}
                                        </div>
                                        <div className='text-container'>
                                            <div className='subtitle'>{`${portfolioItem.frontmatter.title} • ${portfolioItem.frontmatter.client}`}</div>
                                            <div className='title'>{portfolioItem.frontmatter.shortDescription}</div>
                                        </div>
                                    </div>
                                </ItemCard>
                            </Link>
                        ))}
                    </div>
                </section>
                <div className="timeline">
                    <section>
                        <div className="section-intro">
                            <h3>Projekte</h3>
                            <div className="line homepage-line" />
                        </div>
                        {renderTimelineItems(projectExperiences)}
                    </section>
                    <section>
                        <div className="section-intro">
                            <h3>Beruflicher Werdegang</h3>
                            <div className="line homepage-line" />
                        </div>
                        {renderTimelineItems(workExperiences)}
                    </section>
                    <section>
                        <div className="section-intro">
                            <h3>Akademische Ausbildung</h3>
                            <div className="line homepage-line" />
                        </div>
                        {renderTimelineItems(academicExperiences)}
                    </section>
                </div>
            </div>
        </Layout>
    );
}

export default PortfolioPage;

export const pageQuery = graphql`
query ExperiencePageQuery {
    allMarkdownRemark(
        sort: { fields: [frontmatter___date], order: DESC }
        filter: {fileAbsolutePath: {glob: "**/portfolio/**"}}
    ) {
        edges {
            node {
                id
                fields {
                    slug
                }
                frontmatter {
                    shortDescription
                    client
                    title
                    iconImage {
                        publicURL
                        childImageSharp {
                            gatsbyImageData(width: 64, height: 64, layout: FIXED)
                        }
                    }
                    # TODO: Reduce image-payload by down-sizing the teaser-image (responsibly)
                    teaserImage {
                        publicURL
                    }
                    teaserBackgroundSize
                    teaserBackgroundPosition
                }
            }
        }
    }
}
`
