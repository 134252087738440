/* eslint-disable max-len */

// when 'isActive' === `true` will result rendering of datestring with an appended star-icon in the app.
// e.g. "09/2018 — heute ✦"
// EXAMPLE:
/*
    {
        isActive: true, // <- **set to `true`!**
        dateString: "10/2020 — heute", // <- conventionally specify end-date with "heute"
        subtitleString: "1&1 Telecommunication SE – Karlsruhe",
        titleString: "Entwicklung und Wartung der 1&1 Control-Center APP (iOS Team)",
        skills: [
            "Swift",
            "Objective-C",
            "Xcode",
            "REST",
            "Git",
            "Bitbucket",
            "Jira",
            "Confluence",
            "Testflight",
            "Jenkins",
        ],
        descriptionString: null,
    },
*/

export const projectExperiences = [
    {
        isActive: false,
        dateString: "10/2020 — 05/2021",
        subtitleString: "1&1 Telecommunication SE – Karlsruhe",
        titleString: "Entwicklung und Wartung der 1&1 Control-Center APP (iOS Team)",
        skills: [
            "Swift",
            "Objective-C",
            "Xcode",
            "REST",
            "Git",
            "Bitbucket",
            "Jira",
            "Confluence",
            "CI-CD",
            "Testflight",
            "Jenkins",
        ],
        descriptionString: null,
    },
    {
        isActive: false,
        dateString: "2020",
        subtitleString: "maiwald.design – Ostfildern",
        titleString: "Entwicklung einer iPad-App für interaktive Präsentationen",
        descriptionString: "Entwicklung einer iPad-App für Präsentationen mit interaktiven Inhalten (Videos, PDFs).",
        skills: [
            "iOS",
            "Swift 5",
            "Xcode",
            "Git",
        ],
    },
    {
        isActive: false,
        dateString: "2020",
        subtitleString: "L'agence UG & Co KG – Marbach am Neckar",
        titleString: "Entwicklung einer Lern-App für Android",
        descriptionString: "Projektgegenstand war die Entwicklung einer App, die Lerninhalte für Endkunden bereitstellt. Sie ermöglicht dem Nutzer sich per Video-Lektionen Wissen zum Thema Office-Anwendungen anzueignen.",
        skills: [
            "Flutter",
            "Visual Studio Code",
            "Git",
            "JSON",
            "Sketch",
            "Asana",
        ],
    },
];

// when 'isActive' === `true` will result rendering of datestring with an appended star-icon in the app.
// e.g. "09/2018 — heute ✦"
export const workExperiences = [
    {
        isActive: true,
        dateString: "2020 — heute",
        subtitleString: "Kishor Rana Engineering",
        titleString: "Software-Entwickler",
        descriptionString: "Unabhängige Technische Beratung und Entwicklung von Nativen iOS Apps und React-basierten Webapps",
    },
    {
        isActive: false,
        dateString: "2018 — 2019",
        subtitleString: "EXXETA AG – Karlsruhe",
        titleString: "Consultant",
        descriptionString: "Technische Beratung und Entwicklung von Nativen iOS Apps und React-basierten Webapps für Kunden aus dem Bankwesen und Maschinenbau",
    },
    {
        isActive: false,
        dateString: "2017 — 2018",
        subtitleString: "United Digital Group GmbH – Karlsruhe",
        titleString: "Software Developer Mobile",
        descriptionString: "Mobile (iOS)-Entwicklung für Kunden aus verschiedenen Branchen insbesondere Maschinenbau",
    }
];

// when 'isActive' === `true` will result rendering of datestring with an appended star-icon in the app.
// e.g. "09/2018 — heute ✦"
export const academicExperiences = [
    {
        isActive: false,
        dateString: "Abschluss 2017",
        subtitleString: "Hochschule Karlsruhe – Technik und Wirtschaft",
        titleString: "B.Sc. Informatik",
        descriptionString: null,
    }
];
